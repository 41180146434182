import logo from './logo.svg';
import './App.css';
import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import image from './resources/image.jpg';
import Directory from './pages/directory';
import { BrowserRouter, Router, Routes, Route, NavLink, Link} from 'react-router-dom';
import { useNavigate } from "react-router-dom";





function App() {
  return (
    <div className="App">
    <div className="containerMain">
    <h1 className="mainPageText"> The Eden Project </h1>
    <div className="basicInfoDiv">
      <h1 className="subtitle">A work in progress.</h1>
    </div>
    <div className="buttonDiv">
    </div>
    </div>
    </div>
  );
}


export default App;
