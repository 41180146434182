import React from 'react';
import './directory.css';

function Directory (){
    return(
        <div className="directoryMain">

            <div className="directoryHeader">
            <h1 className="mainTitle"> Project Orion </h1>
            <img className="orionLogo" src={require('./orionwhite.png')} />
            </div>

            <video className="videoBackground" src={require('./orionBackground.mp4')} autoPlay loop muted />
            <div className="quickExplanation">
                <h2 className="titleSizeMain"> What is Project Orion? </h2>
                <p className="sizeExplanation"> The self improvement portion of The Eden Project.
                A collection of different goals, information, and other valuable information is stored here. </p>
            </div>
            <div className="directoryContainer">

                </div>












        </div>

    )

}

export default Directory;