
import'./about.css';


function About(){
    return(
    <div className="aboutPage">
     <div className="mainDivContainer">
        <div className="aboutContainer">
            <p> Hi, I'm Eden, I'm a 21 year old cs student. I like learning about various
                things including, but not limited to CS related topics, foreign languages,
                finance/investing, psychiatry, and pharmacology. </p>
                <p> This website highlights the various projects and goals I'm working on. 
                    Health, language learning, and cs are my main focuses. </p>
        </div>

        <div className="languages">
Foreign Languages
<ul className="languageList">
    <li>
        <div className="languageInput">
        <img className="flag" src={require("./united-states.png")}></img>
         <div className="languageChoice">English: Native</div> 
        </div>
    </li>
    <li>
        <div className="languageInput">
        <img className="flag" src={require("./brazil.png")}></img>
         <div className="languageChoice">Portuguese: B2</div> 
        </div>
    </li>
    <li>
        <div className="languageInput">
        <img className="flag" src={require("./spain.png")}></img>
         <div className="languageChoice">Spanish: B1</div> 
        </div>
    </li>
    <li>
        <div className="languageInput">
        <img className="flag" src={require("./france.png")}></img>
         <div className="languageChoice">French: A2</div> 
        </div>
    </li>
    <li>
        <div className="languageInput">
        <img className="flag esperanto" src={require("./esperanto.png")}></img>
         <div className="languageChoice">Esperanto: A1</div> 
        </div>
    </li>
    <li>
        <div className="languageInput">
        <img className="flag " src={require("./russia.png")}></img>
         <div className="languageChoice">Russian: A1</div> 
        </div>
    </li>
    <li>
    <div className="languageInput">
    <a href="/directory"><button className="aboutButton">Project Hermes</button></a>
    </div>
    </li>
    

    </ul>
        </div>
        <div className="health">
            Current Weight: 160lbs
            Current Height: 66in
            
        </div>




      </div>
       








    </div>
    )
}
export default About;